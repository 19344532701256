import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';

const validateDeepObject = (object, values) =>
  Object.keys(object).some((k) => {
    if (object[k] === null || object[k] === undefined) {
      return false;
    } else if (Array.isArray(object[k])) {
      return object[k].length
        ? object[k].some((v) => !values[k].includes(v)) ||
            values[k].some((v) => !object[k].includes(v))
        : false;
    } else if (typeof object[k] === 'object') {
      return Object.keys(object).length
        ? validateDeepObject(object[k], values[k])
        : false;
    }

    return values[k] ? object[k] !== values[k] : true;
  });

const ModalButtons = ({
  form,
  values,
  onOk,
  onDelete,
  okText = 'Guardar',
  deleteText = 'Eliminar',
  disabled = false,
}) => {
  const [isValidForm, setIsValidForm] = useState(false);
  const formValues = useWatch([], form);

  useEffect(() => {
    form?.validateFields({ validateOnly: true }).then(
      () => {
        const isValid = formValues
          ? validateDeepObject(formValues, values)
          : false;
        setIsValidForm(isValid);
      },
      () => {
        setIsValidForm(false);
      }
    );
  }, [form, values, formValues]);

  return (
    <>
      <Button type="primary" onClick={onOk} disabled={!isValidForm || disabled}>
        {okText}
      </Button>
      {onDelete && (
        <Button danger onClick={onDelete} icon={<DeleteOutlined />}>
          {deleteText}
        </Button>
      )}
    </>
  );
};

export default ModalButtons;
