import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import './Content.scss';

const { Content: AntDContent } = Layout;

const Content = () => (
  <AntDContent className="app-content">
    <Outlet />
  </AntDContent>
);

export default Content;
