import { Modal } from 'antd';
import { useEffect } from 'react';

import ModalButtons from '../../../../components/buttons/ModalButtons';
import ClientForm from '../../../../components/forms/ClientForm';

const DEFAULT_VALUES = {
  id: null,
  provider: null,
  planSpeedValue: null,
  planCost: null,
};

const ClientsModal = ({ form, values, open, onOk, onCancel, onDelete }) => {
  useEffect(() => {
    if (values.id) {
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue(DEFAULT_VALUES);
    }
  }, [form, values]);

  const handleDelete = () => {
    form.resetFields();
    form.setFieldsValue({});
    onDelete(values.id);
  };

  return (
    <Modal
      title="Modificar Cliente"
      open={open}
      onCancel={onCancel}
      footer={
        <ModalButtons
          form={form}
          values={values}
          onOk={() => {
            const formValues = form.getFieldsValue();
            onOk({
              ...formValues,
              id: values.id,
            });
          }}
          onDelete={() =>
            Modal.confirm({
              title: 'Estas seguro que deseas eliminar este plan?',
              okText: 'Eliminar',
              cancelText: 'Cancelar',
              onOk: handleDelete,
            })
          }
        />
      }
    >
      <ClientForm
        form={form}
        cancelText="Cancelar"
        values={values}
        hideCreationDate
        hideAddress
      />
    </Modal>
  );
};

export default ClientsModal;
