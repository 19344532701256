import { IdcardOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SaveFormButton = ({ form, text, values }) => {
  const [isValidForm, setIsValidForm] = useState(false);
  const formValues = useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        const isValid = formValues
          ? Object.keys(formValues).some((f) => formValues[f] !== values[f])
          : false;
        setIsValidForm(isValid);
      },
      () => {
        setIsValidForm(false);
      }
    );
  }, [form, formValues, values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!isValidForm}>
      {text}
    </Button>
  );
};

const ProfileForm = ({
  form,
  onFinish,
  submitText,
  onCancel,
  cancelText,
  values,
  onPasswordChange,
}) => {
  const navigate = useNavigate();

  return (
    <Form
      className="user-form"
      form={form}
      name="user_form"
      layout="vertical"
      onFinish={(value) => onFinish(form, value)}
      initialValues={values}
    >
      <div className="form-data">
        <Form.Item
          name="personalId"
          rules={[{ required: true, message: 'Cédula es obligatorio' }]}
        >
          <Input placeholder="Cédula" prefix={<IdcardOutlined />} disabled />
        </Form.Item>

        <Form.Item
          name="username"
          rules={[
            { required: true, message: 'Nombre completo es obligatorio' },
          ]}
        >
          <Input placeholder="Nombre Completo" prefix={<UserOutlined />} />
        </Form.Item>

        <Form.Item name="password">
          <Input.Password
            placeholder="Contraseña"
            prefix={<LockOutlined />}
            disabled
          />
          <Button
            type="link"
            onClick={onPasswordChange}
            style={{ paddingLeft: 0 }}
          >
            Cambiar contraseña
          </Button>
        </Form.Item>
      </div>

      <Form.Item className="form-controls">
        <SaveFormButton form={form} text={submitText} values={values} />
        {onCancel && (
          <Button
            type="text"
            onClick={() => {
              form.resetFields();
              navigate('/');
            }}
          >
            {cancelText}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default ProfileForm;
