import { Navigate } from 'react-router-dom';

import { getCookie } from '../../utils/auth';

const ProtectedRoute = ({ children }) => {
  const user = getCookie();
  if (!user) return <Navigate to="/login" />;
  return children;
};

export default ProtectedRoute;
