import { Layout } from 'antd';
import { createBrowserRouter } from 'react-router-dom';

import Content from './components/content/Content';
import Header from './components/header/Header';
import Login from './components/login/Login';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import SideBar from './components/sidebar/SideBar';
import AccessManager from './pages/configuration/accessManager/AccessManager';
import Clients from './pages/configuration/clients/Clients';
import Plans from './pages/configuration/plans/Plans';
import Providers from './pages/configuration/providers/Providers';
import Users from './pages/configuration/users/Users';
import Connections from './pages/connections/Connections';
import History from './pages/history/History';
import NewInstallation from './pages/newInstallation/NewInstallation';
import Payments from './pages/payments/Payments';
import Profile from './pages/profile/Profile';
import Reports from './pages/reports/Reports';
import { getUserData } from './utils/auth';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Layout className="home-layout">
          <SideBar user={getUserData()} />
          <Layout className="navigation-layout">
            <Header />
            <Content />
          </Layout>
        </Layout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/reports',
        element: <Reports />,
      },
      {
        path: '/newInstallation',
        element: <NewInstallation />,
      },
      {
        path: '/connections',
        element: <Connections />,
      },
      {
        path: '/payments',
        element: <Payments />,
      },
      {
        path: '/history',
        element: <History />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
      {
        path: '/plans',
        element: <Plans />,
      },
      {
        path: '/providers',
        element: <Providers />,
      },
      {
        path: '/users',
        element: <Users />,
      },
      {
        path: '/roles',
        element: <AccessManager />,
      },
      {
        path: '/clients',
        element: <Clients />,
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
]);
