import { jwtDecode } from 'jwt-decode';

export const AUTH_KEY = 'jwt-auth';
export const getCookie = () => {
  const b = document.cookie.match(`(^|;)\\s*${AUTH_KEY}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
};

export const getUserData = () => {
  try {
    return jwtDecode(getCookie());
  } catch (error) {
    return { id: null };
  }
};
