import { DollarOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Select } from 'antd';
import { useEffect, useState } from 'react';

import { currencyParser, formatCurrency } from '../../utils/formatters';
import { fetcher } from '../../utils/requests';

const getProviders = (onSuccess, onError) =>
  fetcher(
    'providers',
    {
      method: 'GET',
    },
    onSuccess,
    onError
  );

const getPlans = (params, onSuccess, onError) =>
  fetcher(
    'plans',
    {
      method: 'GET',
      params,
    },
    onSuccess,
    onError
  );

const PlanForm = ({
  form,
  onFinish,
  submitText,
  onCancel,
  cancelText,
  values,
}) => {
  const [providers, setProviders] = useState([]);
  const [isProviderLoading, setIsProviderLoading] = useState(false);

  const [plans, setPlans] = useState([]);
  const [isPlanLoading, setIsPlanLoading] = useState(false);

  const formValues = Form.useWatch([], form);
  const provider = formValues?.provider;

  useEffect(() => {
    setIsProviderLoading(true);
    getProviders(
      (providers) => {
        setIsProviderLoading(false);
        setProviders(providers);
      },
      (error) => {
        console.log(error);
      }
    );

    setIsPlanLoading(true);
    getPlans(
      provider ? { provider } : {},
      (p) => {
        setIsPlanLoading(false);
        setPlans(p);
      },
      (error) => {
        setIsPlanLoading(false);
        console.log(error);
      }
    );
  }, [provider]);

  return (
    <Form
      form={form}
      name="plan_form"
      initialValues={values}
      className="plan-form"
      onFinish={(value) => onFinish(form, value)}
      layout="vertical"
    >
      <Form.Item
        name="provider"
        rules={[
          { required: true, message: 'El tipo de conexión es obligatorio' },
        ]}
        label="Conexión"
      >
        <Select
          loading={isProviderLoading}
          onChange={(value) => {
            if (value !== provider) {
              setIsPlanLoading(true);
              getPlans(
                value ? { provider: value } : {},
                (p) => {
                  setIsPlanLoading(false);
                  setPlans(p);
                },
                (error) => {
                  setIsPlanLoading(false);
                  console.log(error);
                }
              );
              form.setFieldValue('planSpeed', null);
            }
          }}
          options={providers.map((provider) => ({
            value: provider.id,
            label: provider.providerType,
          }))}
        />
      </Form.Item>

      <Form.Item
        name="planSpeed"
        rules={[{ required: true, message: 'El plan es obligatorio' }]}
        label="Plan"
      >
        <Select
          loading={isPlanLoading}
          onChange={(value) => {
            form.setFieldValue(
              'planCost',
              plans.find((p) => p.id === value).planCost
            );
          }}
          options={plans
            .filter((plan) => plan.provider === provider)
            .map((plan) => ({
              value: plan.id,
              label: plan.planSpeed,
            }))}
          disabled={!provider}
        />
      </Form.Item>

      <Form.Item
        name="planCost"
        rules={[
          { required: true, message: 'El tipo de conexión es obligatorio' },
        ]}
        label="Pago"
      >
        <InputNumber
          style={{ width: '100%' }}
          formatter={formatCurrency}
          parser={currencyParser}
          prefix={<DollarOutlined />}
          disabled={!form.getFieldValue('planSpeed')}
        />
      </Form.Item>

      <Form.Item
        name="paymentDate"
        rules={[{ required: true, message: 'El dia de pago es obligatorio' }]}
        label="Dia de Pago"
      >
        <Select
          loading={isPlanLoading}
          options={['15', '30'].map((v) => ({
            value: v,
            label: v,
          }))}
        />
      </Form.Item>

      <Form.Item className="form-controls">
        <Button type="primary" htmlType="submit">
          {submitText}
        </Button>
        {onCancel && (
          <Button type="text" onClick={() => onCancel(form.getFieldsValue())}>
            {cancelText}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default PlanForm;
