import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { fetcher } from '../../utils/requests';
import './Login.scss';

const LoginButton = ({ form, loading }) => {
  const [isValidForm, setIsValidForm] = useState(false);

  // Watch all values
  const formValues = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsValidForm(true);
      },
      () => {
        setIsValidForm(false);
      }
    );
  }, [form, formValues]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!isValidForm}
      loading={loading}
    >
      Inciar Sesión
    </Button>
  );
};

const loginErroMessageMappings = {
  'Incorrect username': 'Usuario no encontrado',
  'Incorrect password': 'Contraseña incorrecta',
};

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = ({ username, password }) => {
    setIsLoading(true);
    fetcher(
      'login',
      {
        method: 'POST',
        body: {
          username,
          password,
        },
      },
      () => {
        setIsLoading(false);
        navigate('/');
      },
      ({ data, status }) => {
        setIsLoading(false);
        if (status === 404) {
          const { field, message } = data;
          form.setFields([
            {
              name: field,
              errors: [loginErroMessageMappings[message]],
            },
          ]);
        } else {
          const error = {
            field: 'general',
            message: 'Ocurrió un error, intentalo más tarde',
          };
          console.log(error);
        }
      }
    );
  };

  return (
    <div className="login-container">
      <Card title="Iniciar Sesión">
        <Form
          form={form}
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Por favor ingesa el nombre de usuario',
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Usuario" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Porfavor ingresa la contraseña' },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Contraseña"
            />
          </Form.Item>
          <Form.Item>
            <div className="form-options">
              <LoginButton form={form} loading={isLoading} />
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
