import { IdcardOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, InputNumber } from 'antd';
import dayjs from 'dayjs';

const ClientForm = ({
  form,
  onFinish,
  submitText,
  onCancel,
  cancelText,
  values,
  hideCreationDate,
  hideAddress,
}) => {
  if (values) form.setFieldsValue(values);
  return (
    <Form
      form={form}
      initialValues={values}
      name="client_form"
      className="client-form"
      onFinish={(value) => onFinish(form, value)}
      layout="vertical"
    >
      {!hideCreationDate && (
        <Form.Item
          name="createdDate"
          label="Fecha ingreso"
          initialValue={values.createdDate || dayjs(new Date())}
          getValueProps={(d) => ({ value: dayjs(d) })}
          rules={[
            { required: true, message: 'Fecha de ingreso es obligatorio' },
          ]}
        >
          <DatePicker
            format="DD/MM/YYYY"
            placeholder="Fecha Ingreso"
            allowClear={false}
          />
        </Form.Item>
      )}

      <Form.Item
        name="firstName"
        rules={[{ required: true, message: 'Nombres es obligatorio' }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Nombres" />
      </Form.Item>

      <Form.Item
        name="lastName"
        rules={[{ required: true, message: 'Apellidos es obligatorio' }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Apellidos" />
      </Form.Item>

      <Form.Item
        name="personalId"
        rules={[{ required: true, message: 'Cédula es obligatorio' }]}
      >
        <InputNumber
          style={{ width: '100%' }}
          prefix={<IdcardOutlined />}
          placeholder="Cédula"
          maxLength={15}
        />
      </Form.Item>

      <Form.Item
        name="cellphone"
        rules={[{ required: true, message: 'Celular es obligatorio' }]}
      >
        <InputNumber
          style={{ width: '100%' }}
          prefix={<PhoneOutlined />}
          placeholder="Celular"
          maxLength={10}
        />
      </Form.Item>
      {!hideAddress && (
        <Form.Item
          name="address"
          rules={[{ required: true, message: 'La dirección es obligatoria' }]}
        >
          <Input.TextArea placeholder="Dirección" />
        </Form.Item>
      )}
      {submitText && (
        <Form.Item className="form-controls">
          <Button type="primary" htmlType="submit">
            {submitText}
          </Button>
          {onCancel && (
            <Button type="text" onClick={onCancel}>
              {cancelText}
            </Button>
          )}
        </Form.Item>
      )}
    </Form>
  );
};

export default ClientForm;
