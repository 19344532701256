import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';

import ModalButtons from '../../../../components/buttons/ModalButtons';
import { fetcher } from '../../../../utils/requests';
import './AccessManagerModal.scss';

const ROUTES_ENDPOINT = 'roles/routes';
const ROLE_ROUTES_ENDPOINT = 'roles/roleRoutes';
const ACCESS = 'access';

const getRoleRoutes = (roleId, setRoleRoutes, setIsLoading, setError) => {
  fetcher(
    ROLE_ROUTES_ENDPOINT,
    { method: 'GET', params: { roleId } },
    setRoleRoutes,
    (error) => {
      setIsLoading(false);
      setError(error);
    }
  );
};

const getRoutes = (setRoutes, setIsLoading, setError) => {
  fetcher(
    ROUTES_ENDPOINT,
    { method: 'GET' },
    (response) => setRoutes(response),
    (error) => {
      setIsLoading(false);
      setError(error);
    }
  );
};

const RoutesField = ({ form, name, defaultValues, routes }) => {
  const categoryRoutes = routes?.reduce(
    (acc, route) => ({
      ...acc,
      [route.category]: [
        ...(acc[route.category] ? [...acc[route.category]] : []),
        route,
      ],
    }),
    {}
  );

  const [localValues, setLocalValues] = useState({});

  useEffect(() => {
    const selectedRoutes = routes?.reduce(
      (acc, route) => ({
        ...acc,
        [route.category]: [
          ...(acc[route.category] ? [...acc[route.category]] : []),
          ...(defaultValues.some((d) => d.id === route.id) ? [route.id] : []),
        ],
      }),
      {}
    );
    setLocalValues(selectedRoutes);
    form.resetFields([name]);
  }, [name, form, defaultValues, routes]);

  return (
    <Form.Item name={name}>
      <span className="field-title">Permisos</span>
      <div className="checkbox-container">
        {defaultValues &&
          Object.keys(categoryRoutes)?.reduce((acc, category) => {
            return [
              ...acc,
              <div className="checkbox-group">
                <span className="category-title">{category}</span>
                <Checkbox.Group
                  className="checkbox-options"
                  options={categoryRoutes[category].map((r) => ({
                    label: r.displayName,
                    value: r.id,
                  }))}
                  defaultValue={defaultValues
                    ?.filter((r) =>
                      categoryRoutes[category]?.some((d) => d.id === r.id)
                    )
                    .map((r) => r.id)}
                  onChange={(value) => {
                    const newCategoriesValue = {
                      ...localValues,
                      [category]: value,
                    };
                    const selectedValues = Object.keys(
                      newCategoriesValue
                    )?.reduce(
                      (acc, curr) => [...acc, ...newCategoriesValue[curr]],
                      []
                    );
                    form.setFieldValue(ACCESS, selectedValues);
                    setLocalValues(newCategoriesValue);
                  }}
                />
              </div>,
            ];
          }, [])}
      </div>
    </Form.Item>
  );
};

const DEFAULT_VALUES = {
  roleName: '',
  description: '',
  [ACCESS]: [],
};

const AccessManagerModal = ({
  form,
  values,
  open,
  onOk,
  onCancel,
  onDelete,
}) => {
  const [routes, setRoutes] = useState([]);
  const [defaultRoutesByRole, setDefaultRoutesByRole] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getRoutes(
      (response) => {
        setRoutes(response);
        getRoleRoutes(
          values.id,
          (response) => {
            const defaultRoutesByRoles = response.map((r) => ({
              label: r.displayName,
              id: r.id,
            }));
            if (defaultRoutesByRoles.length) {
              setDefaultRoutesByRole(defaultRoutesByRoles);
            }
            setIsLoading(false);
          },
          setIsLoading,
          (error) => console.log(error)
        );
      },
      setIsLoading,
      (error) => console.log(error)
    );
    if (values.id) {
      form.setFieldsValue({
        ...values,
        [ACCESS]: defaultRoutesByRole.map((r) => r.id),
      });
    } else {
      form.setFieldsValue(DEFAULT_VALUES);
      setDefaultRoutesByRole([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, values]);

  const handleDelete = () => {
    setDefaultRoutesByRole([]);
    onDelete(values.id);
  };

  return (
    <Modal
      title={values.id ? 'Modificar Rol' : 'Crear Rol'}
      loading={isLoading}
      open={open}
      onCancel={onCancel}
      footer={
        <ModalButtons
          form={form}
          values={{ ...values, [ACCESS]: defaultRoutesByRole.map((r) => r.id) }}
          onOk={() => {
            const formValues = form.getFieldsValue();
            onOk({
              ...values,
              ...formValues,
            });
          }}
          onDelete={() =>
            Modal.confirm({
              title: 'Estas seguro que deseas eliminar este rol?',
              cancelText: 'Cancelar',
              okText: 'Eliminar',
              okType: 'danger',
              okButtonProps: { icon: <DeleteOutlined /> },
              onOk: handleDelete,
            })
          }
        />
      }
    >
      <Form
        className="roles-form"
        form={form}
        name="roles_form"
        layout="vertical"
      >
        <Form.Item
          name="roleName"
          label={values.roleName ? 'Nombre del rol' : ''}
          rules={[{ required: true, message: 'Nombre es obligatorio' }]}
        >
          <Input
            placeholder="Nombre del rol"
            prefix={<UserOutlined />}
            maxLength={15}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label={values.roleName ? 'Descripción del rol' : ''}
          rules={[
            { required: true, message: 'Descripción del rol es obligatorio' },
          ]}
        >
          <Input.TextArea
            placeholder="Descripción del rol"
            prefix={<UserOutlined />}
            maxLength={50}
          />
        </Form.Item>

        <RoutesField
          form={form}
          name={ACCESS}
          defaultValues={defaultRoutesByRole}
          routes={routes}
        />
      </Form>
    </Modal>
  );
};

export default AccessManagerModal;
