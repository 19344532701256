import { RouterProvider } from 'react-router-dom';

import { router } from './App.router';
import './App.scss';

const App = () => {
  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
