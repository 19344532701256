import {
  LogoutOutlined,
  ProfileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUserData } from '../../utils/auth';
import { logout } from '../../utils/requests';
import Loading from '../loading/Loading';
import './User.scss';

const items = [
  {
    key: 'username',
    type: 'group',
  },
  {
    type: 'divider',
  },
  {
    key: 'profile',
    label: (
      <span style={{ display: 'flex', columnGap: 5 }}>
        <ProfileOutlined style={{ fontSize: 14 }} />
        Perfil
      </span>
    ),
  },
  {
    key: 'logout',
    label: (
      <span style={{ display: 'flex', columnGap: 5, color: 'red' }}>
        <LogoutOutlined style={{ fontSize: 14 }} />
        Cerrar Sesión
      </span>
    ),
  },
];

const getItems = (username) =>
  items.map((i) => (i.key === 'username' ? { ...i, label: username } : i));

const User = ({ className }) => {
  const [isLoading, setIsLoding] = useState(false);
  const { username } = getUserData();
  const navigate = useNavigate();
  const logoutUser = () => {
    setIsLoding(true);
    logout(
      (response) => {
        setIsLoding(false);
        navigate('/login');
      },
      (error) => {
        console.log(error);
        setIsLoding(false);
      },
      navigate
    );
  };

  const handleClick = async ({ key }) => {
    if (key === 'logout') {
      logoutUser();
    }
    if (key === 'profile') {
      navigate('/profile');
    }
  };
  return (
    <div className={`user-menu ${className || ''}`}>
      {isLoading && <Loading />}
      <Dropdown
        menu={{ items: getItems(username), onClick: handleClick }}
        placement="topRight"
        arrow
      >
        <Avatar
          size={40}
          icon={<UserOutlined />}
          style={{ backgroundColor: '#e6f4ff', color: 'black' }}
        />
      </Dropdown>
    </div>
  );
};

export default User;
