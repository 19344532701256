import { Form, Layout, message, Steps } from 'antd';
import { useState } from 'react';

import ClientForm from '../../components/forms/ClientForm';
import PlanForm from '../../components/forms/PlanForm';
import Loading from '../../components/loading/Loading';
import { fetcher } from '../../utils/requests';
import RouterForm from './forms/RouterForm';
import './History.scss';

const { Header, Content } = Layout;

const items = [
  {
    key: 'client-form',
    title: 'Cliente',
  },
  {
    key: 'plan-form',
    title: 'Plan',
  },
  {
    key: 'router-form',
    title: 'Red',
  },
];

const History = () => {
  const [current, setCurrent] = useState(0);
  const [clientValues, setClientValues] = useState({});
  const [planValues, setPlanValues] = useState({});
  const [routerValues, setRouterValues] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const next = () => {
    form.resetFields();
    setCurrent(current + 1);
  };

  const prev = () => {
    form.resetFields();
    setCurrent(current - 1);
  };

  const resetState = () => {
    setCurrent(0);
    form.resetFields();
    setClientValues({});
    setPlanValues({});
    setRouterValues({});
  };

  const onFinish = async (finishedForm, value) => {
    switch (finishedForm.__INTERNAL__.name) {
      case 'client_form':
        next();
        setClientValues(value);
        break;

      case 'plan_form':
        next();
        setPlanValues(value);
        break;

      case 'router_form':
        setRouterValues(value);
        setIsLoading(true);
        await fetcher(
          'history',
          {
            method: 'POST',
            body: {
              plan: planValues,
              client: clientValues,
              router: value,
              date: clientValues.createdDate,
            },
          },
          () => {
            messageApi.success('Se registró correctamente', 5);
            setIsLoading(false);
            next();
            resetState();
          },
          () => {
            messageApi.error('Ocurrió un error, intentalo de nuevo', 5);
            setIsLoading(false);
          }
        );
        break;

      default:
        break;
    }
  };

  const content = [
    <ClientForm
      form={form}
      values={clientValues}
      onFinish={onFinish}
      submitText="Siguiente"
    />,
    <PlanForm
      form={form}
      values={planValues}
      onFinish={onFinish}
      submitText="Siguiente"
      onCancel={(values) => {
        setPlanValues(values);
        prev();
      }}
      cancelText="Anterior"
    />,
    <RouterForm
      form={form}
      values={routerValues}
      onFinish={onFinish}
      submitText="Finalizar"
      onCancel={(values) => {
        setRouterValues(values);
        prev();
      }}
      cancelText="Anterior"
    />,
  ];

  return (
    <>
      {isLoading && <Loading />}
      <Layout className="history">
        {contextHolder}
        <Header className="history-header">
          <span className="title">Histórico</span>
          <Steps
            size="small"
            items={items}
            current={current}
            direction="horizontal"
          />
        </Header>
        <Content className="history-content">{content[current]}</Content>
      </Layout>
    </>
  );
};

export default History;
