import { PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Form, Layout, Tooltip } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { useEffect, useState } from 'react';

import Loading from '../../../components/loading/Loading';
import TableComponent from '../../../components/table/TableComponent';
import { hidePassword } from '../../../utils/formatters';
import { fetcher } from '../../../utils/requests';
import ProvidersModal from './modal/ProvidersModal';
import './Providers.scss';

const ENDPOINT = 'providers';
const HEADER_FORMATTERS = {
  providerType: () => 'Nombre Proveedor',
  publicIp: () => 'Ip pública',
  username: () => 'Usuario',
  password: () => 'Contraseña',
};

const DATA_FORMATTERS = {
  password: (v) => hidePassword(v),
};

const getData = (setSchema, setProviders, setIsLoading, setError) => {
  setIsLoading(true);
  fetcher(
    `${ENDPOINT}/schema`,
    { method: 'GET' },
    (response) => {
      setSchema(response);
      fetcher(
        ENDPOINT,
        { method: 'GET' },
        (response) => {
          setProviders(response);
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    },
    (error) => {
      setIsLoading(false);
      setError(error);
      console.log(error);
    }
  );
};

const Providers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [providers, setProviders] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    getData(setColumns, setProviders, setIsLoading, (error) =>
      console.log(error)
    );
  }, []);

  const onRowClicked = (plan) => {
    setOpenModal(true);
    setSelectedRow(plan);
  };

  const handleModalCancel = () => {
    setOpenModal(false);
    setSelectedRow({});
  };

  const handleModalOk = (values) => {
    setOpenModal(false);
    setIsLoading(true);
    if (values.id) {
      fetcher(
        ENDPOINT,
        { method: 'PUT', body: values },
        () => {
          setProviders(
            providers.map((p) => (p.id === values.id ? { ...values } : p))
          );
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    } else {
      fetcher(
        ENDPOINT,
        { method: 'POST', body: values },
        ({ id }) => {
          setProviders([...providers, { ...values, id }]);
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    }
    setSelectedRow({});
  };

  const handleDelete = (id) => {
    setOpenModal(false);
    setIsLoading(true);
    fetcher(
      ENDPOINT,
      { method: 'DELETE', body: { id } },
      (response) => {
        setProviders(providers.filter((p) => p.id !== id));
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );
    setSelectedRow({});
  };

  return (
    <>
      {isLoading && <Loading />}
      <Layout className="providers">
        <Header className="providers-header">
          <span className="title">
            Proveedores
            <Tooltip title="Los diferentes Router Boards a los que se puede conectar un cliente">
              <QuestionCircleOutlined style={{ color: 'blue', fontSize: 14 }} />
            </Tooltip>
          </span>
        </Header>
        <Content className="providers-content">
          <Button
            className="create-button"
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setOpenModal(true);
              setSelectedRow({});
            }}
          >
            Nuevo Proveedor
          </Button>

          <TableComponent
            headerFormatters={HEADER_FORMATTERS}
            dataFormatters={DATA_FORMATTERS}
            columns={columns}
            data={providers}
            onClick={onRowClicked}
          />

          <ProvidersModal
            form={form}
            open={openModal}
            onCancel={handleModalCancel}
            onOk={handleModalOk}
            onDelete={handleDelete}
            values={selectedRow}
          />
        </Content>
      </Layout>
    </>
  );
};

export default Providers;
