import {
  ApartmentOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';

import ModalButtons from '../../../components/buttons/ModalButtons';
import ClientForm from '../../../components/forms/ClientForm';
import Loading from '../../../components/loading/Loading';
import { currencyParser, formatCurrency } from '../../../utils/formatters';
import { fetcher } from '../../../utils/requests';
import './UpdateConnectionModal.scss';

const UpdateConnectionModal = ({
  form,
  values,
  open,
  onCancel,
  onOk,
  dependencies,
  onDelete,
}) => {
  const [openNewClientModal, setOpenNewClientModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientFormRef] = useForm();
  const [clients, setClients] = useState(dependencies?.client);
  const [selectedClient, setSelectedClient] = useState(values?.client);

  const { plan, router } = dependencies;
  const clientProvider = plan?.find((p) => p.id === values?.plan);
  const filteredPlans = plan?.filter(
    (p) => p.provider === clientProvider?.provider
  );

  useEffect(() => {
    form.setFieldsValue(values);
  }, [form, values]);

  const onSaveClient = () => {
    setIsLoading(true);
    const values = clientFormRef.getFieldsValue();
    fetcher(
      'clients',
      { method: 'POST', body: values },
      ({ id }) => {
        setClients([...clients, { ...values, id }]);
        setSelectedClient(id);
        form.setFieldsValue({ address: values.address, client: id });
        setIsLoading(false);
        setOpenNewClientModal(false);
      },
      async (error) => {
        setIsLoading(false);
        console.log(error);
        if (error.status === 409) {
          clientFormRef.setFields([
            {
              name: 'personalId',
              errors: ['Ya existe un cliente registrado con esta cédula.'],
            },
          ]);
        }
      }
    );
  };

  return (
    <>
      {isLoading && <Loading />}
      <Modal
        title={`Cambiar conexión ${values.state === 'inactive' ? '- No disponible' : ''}`}
        open={open}
        onCancel={onCancel}
        footer={
          <ModalButtons
            form={form}
            values={values}
            disabled={values.state === 'inactive'}
            deleteText="Desconectar"
            onOk={() => {
              const formValues = form.getFieldsValue();
              onOk({
                ...values,
                ...formValues,
              });
            }}
            onDelete={() =>
              Modal.confirm({
                title: 'Estas seguro que quieres desactivar esta conexión?',
                cancelText: 'Cancelar',
                okText: 'Desconectar',
                okType: 'danger',
                okButtonProps: { icon: <DeleteOutlined /> },
                onOk: () => onDelete({ ...values, ...form.getFieldsValue() }),
              })
            }
          />
        }
      >
        <Form
          className="client-transfer-form"
          form={form}
          name="client_transfer_form"
          layout="vertical"
        >
          <Form.Item
            name="client"
            label={values?.client ? 'Usuario' : ''}
            rules={[{ required: true, message: 'El usuario es obligatorio' }]}
          >
            <div className="select-form-item">
              <Select
                placeholder="Cliente"
                suffixIcon={
                  <ApartmentOutlined style={{ color: 'black', fontSize: 14 }} />
                }
                optionFilterProp="label"
                defaultValue={values?.client}
                value={selectedClient}
                options={clients?.map((d) => ({
                  value: d.id,
                  label: `${d.firstName} ${d.lastName} - ${d.personalId}`,
                }))}
                onChange={(client) => {
                  form.setFieldValue('client', client);
                  setSelectedClient(client);
                }}
                showSearch
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setOpenNewClientModal(true)}
              />
            </div>
          </Form.Item>

          <div className="plan-fields">
            <Form.Item
              className="plan-select-field"
              name="plan"
              label={values?.plan ? 'Plan' : ''}
              rules={[{ required: true, message: 'El usuario es obligatorio' }]}
            >
              <Select
                placeholder="Plan"
                suffixIcon={
                  <ApartmentOutlined style={{ color: 'black', fontSize: 14 }} />
                }
                onChange={(value) => {
                  form.setFieldValue(
                    'negociatedValue',
                    filteredPlans?.find((p) => p.id === value)?.planCost
                  );
                }}
                options={filteredPlans?.map((p) => ({
                  value: p.id,
                  label: p.planSpeed,
                }))}
              />
            </Form.Item>

            <Form.Item
              name="negociatedValue"
              label={values?.negociatedValue ? 'Valor plan' : ''}
              rules={[
                { required: true, message: 'El valor del plan es obligatoria' },
              ]}
            >
              <InputNumber
                placeholder="Valor plan"
                formatter={formatCurrency}
                parser={currencyParser}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="router"
            label={values?.router ? 'Router' : ''}
            rules={[{ required: true, message: 'El usuario es obligatorio' }]}
          >
            <Select
              placeholder="Router"
              suffixIcon={
                <ApartmentOutlined style={{ color: 'black', fontSize: 14 }} />
              }
              disabled
              options={router?.map((r) => ({ value: r.id, label: r.ip }))}
            />
          </Form.Item>

          <Form.Item
            name="address"
            label={values?.address ? 'Dirección' : ''}
            rules={[{ required: true, message: 'La dirección es obligatoria' }]}
          >
            <Input.TextArea placeholder="Dirección" />
          </Form.Item>

          <Form.Item
            name="paymentDate"
            label={values?.paymentDate ? 'Periodo de pago' : ''}
            rules={[
              { required: true, message: 'El valor del plan es obligatoria' },
            ]}
          >
            <Select
              placeholder="Periodo de pago"
              options={[
                { value: 15, label: 15 },
                { value: 30, label: 30 },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="wifiName"
            label={!router?.wifiName ? 'Nombre del Wifi' : ''}
            rules={[{ required: true, message: 'La dirección es obligatoria' }]}
          >
            <Input placeholder="Nombre del Wif" />
          </Form.Item>

          <Form.Item
            name="wifiPassword"
            label={!router?.wifiPassword ? 'Contraseña del wifi' : ''}
            rules={[{ required: true, message: 'La dirección es obligatoria' }]}
          >
            <Input placeholder="Contraseña del wifi" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={'Crear cliente'}
        open={openNewClientModal}
        onCancel={() => setOpenNewClientModal(false)}
        onOk={onSaveClient}
      >
        <ClientForm form={clientFormRef} values={{}} hideCreationDate />
      </Modal>
    </>
  );
};

export default UpdateConnectionModal;
