import { Layout } from 'antd';

import './Header.scss';
import User from './User';

const { Header: AntDHeader } = Layout;

const Header = () => {
  return (
    <AntDHeader className="app-header">
      <User />
    </AntDHeader>
  );
};

export default Header;
