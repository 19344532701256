import { Navigate } from 'react-router-dom';

import { getUserData } from './auth';

export const fetcher = (
  url,
  { method, body, params },
  onSuccess,
  onError = (error) => console.log(error),
  // eslint-disable-next-line no-unused-vars
  _redirect = () => {}
) => {
  const { id } = getUserData();
  fetch(`api/${url}${params ? `?${new URLSearchParams(params)}` : ''}`, {
    method,
    body: body ? JSON.stringify({ date: new Date(), user: id, ...body }) : null,
  })
    .then(async (response) => {
      if (response.ok) {
        onSuccess(await response.json());
      } else if (response.status === 302) {
        const { route } = await response.json();
        Navigate(route, { replace: true });
        onError({
          status: response.status,
          data: [],
        });
      } else {
        onError({
          status: response.status,
          data: await response.json(),
        });
      }
    })
    .catch((error) => onError(error));
};

export const logout = (onSuccess, onError, navigate) =>
  fetcher(
    'logout',
    { method: 'GET' },
    (response) => {
      onSuccess(response);
      window.localStorage.removeItem('token');
      navigate('/login', { replace: true });
    },
    (error) => onError(error)
  );
