import {
  DollarOutlined,
  PartitionOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Form, InputNumber, Modal, Select } from 'antd';
import { useEffect } from 'react';

import ModalButtons from '../../../../components/buttons/ModalButtons';
import { currencyParser, formatCurrency } from '../../../../utils/formatters';

const { Option } = Select;

const DEFAULT_VALUES = {
  id: null,
  provider: null,
  planSpeedValue: null,
  planCost: null,
};

export const formatPlanSpeed = (value) => (value ? `${value} Mbs` : '');
const parsePlanSpeed = (value) => value.replaceAll('Mbs', '').trim();

const PlansModal = ({
  form,
  values,
  dependencies,
  open,
  onOk,
  onCancel,
  onDelete,
}) => {
  useEffect(() => {
    if (values.id) {
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue(DEFAULT_VALUES);
    }
  }, [form, values]);

  const handleDelete = () => {
    form.resetFields();
    form.setFieldsValue({});
    onDelete(values.id);
  };

  return (
    <Modal
      title={values.id ? 'Modificar plan' : 'Crear plan'}
      open={open}
      onCancel={onCancel}
      footer={
        <ModalButtons
          form={form}
          values={values}
          onOk={() => {
            const formValues = form.getFieldsValue();
            onOk({
              ...formValues,
              id: values.id,
              planSpeed: formatPlanSpeed(formValues.planSpeedValue),
            });
          }}
          onDelete={() =>
            Modal.confirm({
              title: 'Estas seguro que deseas eliminar este plan?',
              okText: 'Eliminar',
              cancelText: 'Cancelar',
              onOk: handleDelete,
            })
          }
        />
      }
    >
      <Form
        className="password-form"
        form={form}
        name="password_form"
        layout="vertical"
      >
        <Form.Item
          name="provider"
          label={values.provider ? 'Proveedor' : ''}
          rules={[{ required: true, message: 'Proveedor es olbigatorio' }]}
        >
          <Select
            placeholder="Selecciona un proveedor"
            suffixIcon={
              <PartitionOutlined style={{ color: 'black', fontSize: 14 }} />
            }
          >
            {dependencies.provider.map((d) => (
              <Option key={d.id} value={d.id}>
                {d.providerType}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="planSpeedValue"
          label={values.planSpeedValue ? 'Plan' : ''}
          rules={[
            { required: true, message: 'Velocidad del plan es obligatorio' },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Plan"
            prefix={<UserOutlined />}
            formatter={formatPlanSpeed}
            parser={parsePlanSpeed}
          />
        </Form.Item>

        <Form.Item
          name="planCost"
          label={values.planCost ? 'Valor del plan' : ''}
          rules={[
            { required: true, message: 'El valor del plan es obligatorio' },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            formatter={formatCurrency}
            parser={currencyParser}
            placeholder="Valor del plan"
            prefix={<DollarOutlined />}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PlansModal;
